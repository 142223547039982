<template>
  <div class="pt-5 m-3 page-header align-items-start min-vh-50 pb-11 border-radius-lg" :style="{
    background:
      'url(' + require('@/assets/img-minhastar/trabalhador-de-escritorio-usando-o-telefone-celular-no-trabalho.jpg') + ') bottom',
      backgroundSize: 'cover'
  }">
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container">
      <div class="row justify-content-center">
        <div class="mx-auto text-center col-lg-5">
          <h1 class="mt-5 mb-2 text-white">Bem vindo!</h1>
          <p class="text-white text-lead">
            Entre em sua conta inserindo os dados abaixo.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row mt-lg-n10 mt-md-n11 mt-n10 justify-content-center">
      <div class="mx-auto col-xl-4 col-lg-5 col-md-7">
        <div class="card z-index-0">
          <div class="pt-4 text-center card-header">
            <h5>Login</h5>
          </div>
          <div class="card-body">
            <form role="form" @submit="(e) => { e.preventDefault() }">
              <div class="mb-3">
                <input class="form-control" v-maska="['###.###.###-##', '##.###.###/####-##']" id="login" type="text" v-model="login.Login" placeholder="CPF ou CNPJ"
                  aria-label="CPF ou CNPJ" required minlength="11"  />
              </div>
              <div class="mb-3">
                <input class="form-control" id="password" type="password" v-model="login.Senha" placeholder="Senha"
                  aria-label="Password" required/>
              </div>
              <soft-checkbox id="flexCheckDefault" name="flexCheckDefault" class="font-weight-light" checked>
                Lembrar dados
              </soft-checkbox>

              <div class="text-center">
                <soft-button color="dark" full-width variant="gradient" class="my-4 mb-2"
                  @click="Login">Entrar</soft-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer />
</template>

<script>
import AppFooter from "@/examples/PageLayout/Footer.vue";
import SoftCheckbox from "@/components/SoftCheckbox.vue";
import SoftButton from "@/components/SoftButton.vue";
import { useAuthStore } from "../../stores/auth";
let AuthStore;


import { mapMutations } from "vuex";

export default {
  name: "SignupBasic",
  components: {
    AppFooter,
    SoftCheckbox,
    SoftButton,
  },
  data() {
    return {
      AuthStore,
      login: {
        Login: "",
        Senha: "",
      },
    }
  },
  created() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  beforeUnmount() {
    this.toggleEveryDisplay();
    this.toggleHideConfig();
  },
  methods: {
    ...mapMutations(["toggleEveryDisplay", "toggleHideConfig"]),
    async Login() {
      if (!this.login.Login || !this.login.Senha) {
        this.$notify({ type: "error", text: "Os dados informados são inválidos.", duration: 5000 });
      } else if (this.login.Senha.length < 6) {
        this.$notify({ type: "error", text: "A senha deve ter 6 ou mais caracteres.", duration: 5000 });
      } else {
        try {
          var resposta = await AuthStore.LoginPremium(this.login).catch(() => {
            this.$notify({ type: "error", text: "Algo deu errado! Tente novamente.", duration: 5000, });
          });
          if (resposta) {
            this.$notify({ type: "error", text: resposta.msg, duration: 5000 });
          } else {
            const toPath = this.$route.query.to || "/dashboard";
            this.$router.push(toPath);
          }
        } catch (err) {
          if (err.response.data.detail) {
            this.$notify({ type: "error", text: "Algo deu errado! Tente novamente." });
          }
        }
      }

    }
  },
  setup() {
    AuthStore = useAuthStore();
  }
};
</script>
